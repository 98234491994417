import React from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'antd';

import Header from './header';

import './index.css';

const Garden = (props) => {
  return (
    <div>
      <Header />
      <Row>
        <Col xl={8} xs={0}></Col>
        <Col xl={8} xs={24}>
          <Row>
            <Col xl={12} xs={12}>
              <div className="botao">
                <Link to="/ficha-interesse">
                  <Button type="primary" shape="round" block>FICHA CLIENTE</Button>
                </Link>
              </div>
            </Col>
            <Col xl={12} xs={12}>
              <div className="botao">
                <Link to="/">
                  <Button type="primary" shape="round" block>INÍCIO</Button>
                </Link>
              </div>
            </Col>
          </Row>          
        </Col>
        <Col xl={8} xs={0}></Col>
      </Row>
      <Row justify='center'>
        <Col xl={8} xs={0}></Col>
        <Col xl={8} xs={24}>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/garden/logo.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Logo</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/garden/implantacao.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Implantação</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/garden/convencao_condominio.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Convenção Condominial</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/garden/tabela.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Tabela Nova Ipanema Garden</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/lagos4/tabelas-comerciais.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Tabelas Comerciais</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/garden/regimento_interno.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Regimento Interno</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/garden/ficha_cadastral.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Ficha Cadastral</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/garden/manual_corretor.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Manual do Corretor</Button>
              </Link>            
            </Col>
          </Row>
        </Col>
        <Col xl={8} xs={0}></Col>
      </Row>
    </div>
  )}

  export default Garden;
