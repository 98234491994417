import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form, Row, Col, Input, Button, Space} from 'antd';

import Header from '../header';
import {enviarEmail} from '../../store/actions/envioEmail';
import {MAIL_TO_INTERESSE} from '../../consts';

import './index.css';

const corpoEmail = (corpo) => {
  const tabela = `<table>
                    <tr>
                      <td>Nome</td>
                      <td>${corpo.nomeCliente}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>${corpo.emailCliente}</td>
                    </tr>
                    <tr>
                      <td>Celular</td>
                      <td>${corpo.celularCliente}</td>
                    </tr>
                    <tr>
                      <td>Nome cônjuge</td>
                      <td>${corpo.nomeConjuge}</td>
                    </tr>
                    <tr>
                      <td>Email  cônjuge</td>
                      <td>${corpo.emailConjuge}</td>
                    </tr>
                    <tr>
                      <td>Celular  cônjuge</td>
                      <td>${corpo.celularConjuge}</td>
                    </tr>
                    <tr>
                      <td>Empreendimento</td>
                      <td>${corpo.empreendimento}</td>
                    </tr>
                    <tr>
                      <td>lote</td>
                      <td>${corpo.lote}</td>
                    </tr>
                    <tr>
                      <td>Nome corretor</td>
                      <td>${corpo.nomeCorretor}</td>
                    </tr>
                    <tr>
                      <td>E-mail corretor</td>
                      <td>${corpo.emailCorretor}</td>
                    </tr>
                    <tr>
                      <td>Celular corretor</td>
                      <td>${corpo.celularCorretor}</td>
                    </tr>
                    <tr>
                      <td>Imobiliária</td>
                      <td>${corpo.imobiliaria}</td>
                    </tr>
                  </table>`;
  return tabela;
};
class FichaInteresse extends Component {
  formRef = React.createRef();

  onFinish = (values) => {
    const bodyText = corpoEmail(values.cliente);
    const email = {
      mailTo: MAIL_TO_INTERESSE,
      subject: 'Ficha interesse cliente',
      bodyText,
    }
    this.props.enviarEmail(email);
  }

  onReset = () => {
    this.formRef.current.resetFields();
  }

  render() {
    return (
      <div>
        <Header />
        <Form ref={this.formRef} onFinish={this.onFinish}>
          <Row>
            <Col xl={7}></Col>
            <Col xl={10}>
              <Row justify='center'>
                <Col>
                  <h2 className="titulo-h2">FICHA DE INTERESSE</h2>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{backgroundColor:'silver', paddingTop:20, paddingBottom:20}}>
                  <Row>
                    <Col>
                      <h3 className="titulo-h3">Cliente</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={22}>
                      <Form.Item
                        label="Nome"
                        wrapperCol={21}
                        labelCol={3}
                        name={['cliente', 'nomeCliente']}
                        rules={[
                          {
                            required: true,
                            message: 'Nome é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe nome cliente" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <Form.Item
                          label="E-Mail"
                          wrapperCol={21}
                          labelCol={3}
                          name={['cliente', 'emailCliente']}
                          rules={[
                            {
                              type: 'email',
                              message: 'E-mail inválido',
                            },
                            {
                              required: true,
                              message: 'E-mail é obrigatório',
                            }
                          ]}
                        >
                          <Input placeholder="Informe e-mail cliente" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Celular"
                        wrapperCol={20}
                        labelCol={4}
                        name={['cliente', 'celularCliente']}
                        rules={[
                          {
                            required: true,
                            message: 'Celular é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Inf. celular" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3 className="titulo-h3">Cônjuge</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={22}>
                      <Form.Item
                        label="Nome"
                        wrapperCol={21}
                        labelCol={3}
                        name={['cliente', 'nomeConjuge']}
                      >
                        <Input placeholder="Informe nome cônjuge" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <Form.Item
                          label="E-Mail"
                          wrapperCol={21}
                          labelCol={3}
                          name={['cliente', 'emailConjuge']}
                          rules={[
                            {
                              type: 'email',
                              message: 'E-mail inválido',
                            }
                          ]}
                        >
                          <Input placeholder="Informe e-mail cônjuge" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Celular"
                        wrapperCol={20}
                        labelCol={4}
                        name={['cliente', 'celularConjuge']}
                      >
                        <Input placeholder="Cel. conj." />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={22}>
                      <Form.Item
                        label="Empreendimento:"
                        wrapperCol={20}
                        labelCol={4}
                        name={['cliente', 'empreendimento']}
                        rules={[
                          {
                            required: true,
                            message: 'Empreendimento é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe empreendimento" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={22}>
                      <Form.Item
                        label="Lote:"
                        wrapperCol={20}
                        labelCol={4}
                        name={['cliente', 'lote']}
                        rules={[
                          {
                            required: true,
                            message: 'Lote é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe lote" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3 className="titulo-h3">Corretor</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={22}>
                      <Form.Item
                        label="Nome"
                        wrapperCol={21}
                        labelCol={3}
                        name={['cliente', 'nomeCorretor']}
                        rules={[
                          {
                            required: true,
                            message: 'Nome corretor é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe nome corretor" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <Form.Item
                          label="E-Mail"
                          wrapperCol={21}
                          labelCol={3}
                          name={['cliente', 'emailCorretor']}
                          rules={[
                            {
                              type: 'email',
                              message: 'E-mail inválido',
                            },
                            {
                              required: true,
                              message: 'E-mail corretor é obrigatório',
                            }
                          ]}
                        >
                          <Input placeholder="Informe e-mail corretor" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Celular"
                        wrapperCol={20}
                        labelCol={4}
                        name={['cliente', 'celularCorretor']}
                        rules={[
                          {
                            required: true,
                            message: 'Celular corretor é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Cel. corr." />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={22}>
                      <Form.Item
                        label="Imobiliária"
                        wrapperCol={20}
                        labelCol={4}
                        name={['cliente', 'imobiliaria']}
                      >
                        <Input placeholder="Informe imobiliária" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify='center'>
                    <Col span={8}>
                      <div className="botao">
                        <Space>
                          <Button type="primary" shape="round" htmlType="submit">Enviar</Button>                          
                          <Button type="primary" shape="round" onClick={this.onReset}>Limpar</Button>
                        </Space>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      <div className="botao">
                        <Link to="/downloads/ficha_cliente.pdf" download target="_blank">
                          <Button type="primary" shape="round" block>FICHA CLIENTE</Button>
                        </Link>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="botao">
                        <Link to="/">
                          <Button type="primary" shape="round" block>INÍCIO</Button>
                        </Link>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="botao">
                        <Link to="/ilha-verde">
                          <Button type="primary" shape="round" block>VOLTAR</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xl={7}></Col>
          </Row>
        </Form>
      </div>
    )
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    enviarEmail: (email) => dispatch(enviarEmail(email)),
  };
};

export default connect(null, mapDispatchToProps)(FichaInteresse);
