import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form, Row, Col, Input, Button, Radio, Space} from 'antd';

import Header from '../header';
import {MAIL_TO_INTERESSE} from '../../consts';

import {enviarEmail} from '../../store/actions/envioEmail';

import './index.css';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const corpoEmail = (corpo) => {
  const tabela = `<table>
                    <tr>
                      <td>Nome</td>
                      <td>${corpo.nome}</td>
                    </tr>
                    <tr>
                      <td>Cpf</td>
                      <td>${corpo.cpf}</td>
                    </tr>
                    <tr>
                      <td>Creci</td>
                      <td>${corpo.creci}</td>
                    </tr>
                    <tr>
                      <td>E-Mail</td>
                      <td>${corpo.email}</td>
                    </tr>
                    <tr>
                      <td>Cpf</td>
                      <td>${corpo.endereco}</td>
                    </tr>
                    <tr>
                      <td>WhatsApp</td>
                      <td>${corpo.whatsapp}</td>
                    </tr>
                    <tr>
                      <td>Celular</td>
                      <td>${corpo.celular}</td>
                    </tr>
                    <tr>
                      <td>Perfil</td>
                      <td>${(corpo.perfil === 'A') ? 'Autonomo' : 'Imobiliária'}</td>
                    </tr>
                    <tr>
                      <td>Imobiliária</td>
                      <td>${corpo.imobiliaria}</td>
                    </tr>
                  </table>`;
  return tabela;
};
class FichaCadastral extends Component {
  state = {
    requerImobiliaria: false,
  }
  formRef = React.createRef();

  onFinish = (values) => {
    const bodyText = corpoEmail(values.corretor);
    const email = {
      mailTo: MAIL_TO_INTERESSE,
      subject: 'Ficha cadastro corretor',
      bodyText,
    }
    this.props.enviarEmail(email);
  }

  onReset = () => {
    this.formRef.current.resetFields();
  }

  onChangePerfil = (e) => {
    this.setState({
      requerImobiliaria: (e.target.value === 'I'),
    })
  }

  render() {    
    return (
      <div>
        <Header />
        <Row>
          <Col xl={7}></Col>
          <Col xl={10}>
            <Row justify='center'>
              <Col>
                <h2 className="titulo-h2">FICHA CADASTRAL DE CORRETORES</h2>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{backgroundColor:'silver', paddingTop:20, paddingBottom:20}}>
                <Row>
                  <Col span={22}>
                    <Form
                      ref={this.formRef}
                      layout="horizontal"
                      onFinish={this.onFinish}
                      {...formItemLayout}
                      >
                      <Form.Item
                        label="Nome"
                        name={['corretor', 'nome']}
                        rules={[
                          {
                            required: true,
                            message: 'Nome é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe nome" />
                      </Form.Item>
                      <Form.Item
                        label="CPF"
                        name={['corretor', 'cpf']}
                        rules={[
                          {
                            required: true,
                            message: 'CPF é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe CPF" />
                      </Form.Item>
                      <Form.Item
                        label="CRECI"
                        name={['corretor', 'creci']}
                        rules={[
                          {
                            required: true,
                            message: 'CRECI é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe CRECI" />
                      </Form.Item>
                      <Form.Item
                        label="E-Mail"
                        name={['corretor', 'email']}
                        rules={[
                          {
                            type: 'email',
                            message: 'E-mail inválido',
                          },
                          {
                            required: true,
                            message: 'E-mail é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe E-Mail" />
                      </Form.Item>
                      <Form.Item
                        label="Endereço"
                        name={['corretor', 'endereco']}
                        rules={[
                          {
                            required: true,
                            message: 'Endereço é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe Endereço" />
                      </Form.Item>
                      <Form.Item
                        label="WhatsApp"
                        name={['corretor', 'whatsapp']}
                        rules={[
                          {
                            required: true,
                            message: 'WhatsApp é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe WhatsApp" />
                      </Form.Item>
                      <Form.Item
                        label="Celular"
                        name={['corretor', 'celular']}
                        rules={[
                          {
                            required: true,
                            message: 'Celular é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="Informe celular" />
                      </Form.Item>
                      <Form.Item
                        label="Perfil"
                        name={['corretor', 'perfil']}
                        rules={[
                          {
                            required: true,
                            message: 'Perfil é obrigatório',
                          }
                        ]}
                      >
                        <Radio.Group onChange={this.onChangePerfil}>
                          <Radio value="A">Autonomo</Radio>
                          <Radio value="I">Imobiliária</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label="Imobiliária"
                        name={['corretor', 'imobiliaria']}
                        rules={[
                          {
                            required: this.state.requerImobiliaria,
                            message: 'Imobiliária é obrigatório',
                          }
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item wrapperCol={{span: 12, offset: 6}}>
                        <div className="botao">
                          <Space>
                            <Button type="primary" shape="round" htmlType="submit">Enviar</Button>                          
                            <Button type="primary" shape="round" onClick={this.onReset}>Limpar</Button>
                          </Space>
                        </div>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>            
            <Row>
              <Col span={24}>
                <div className="botao">
                  <Link to="/">
                    <Button type="primary" shape="round" block>INÍCIO</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={7}></Col>
        </Row>
      </div>
    )
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    enviarEmail: (email) => dispatch(enviarEmail(email)),
  };
};

export default connect(null, mapDispatchToProps)(FichaCadastral);
