import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Login from '../../views/login';
import FichaCadastral from '../../views/fichaCadastral';

const PublicRoutes = (props) => {
  return (
    <Switch>
      <Route exact path="/ficha-cadastral" component={FichaCadastral} />
      <Route exact path="/" component={Login} />
    </Switch>
  )
};

export default PublicRoutes;