import {TOKEN_VALIDATED, USER_FETCHED, USER_LOGOUT} from '../../actions/auth/types';

const userKey = '_corretor_token';
const INITIAL_STATE = {
  // user: JSON.parse(localStorage.getItem(userKey)),
  user: {},
  validToken: false,
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOKEN_VALIDATED:
      if(action.payload) {
        return { ...state, validToken: true }
      } else {
        localStorage.removeItem(userKey);
        return { ...state, validToken: false, user: null }
      }
    case USER_FETCHED:
      // localStorage.setItem(userKey, JSON.stringify(action.payload));
      return { ...state, user: action.payload, validToken: true }
    case USER_LOGOUT:
        return { ...state, user: {}, validToken: false }
    default:
      return state;
  }
}

export default authReducer;
