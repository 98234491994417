import {combineReducers} from 'redux';
import authReducer from './auth';
import {reducer as toastrReducer} from 'react-redux-toastr';

const reducers = combineReducers({
  authReducer,
  toastr: toastrReducer,
});

export default reducers;
