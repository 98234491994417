import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'antd';

import Header from '../header';
import {logout} from '../../store/actions/auth';

import './index.css'

const Empreendimentos = (props) => {
  return (
    <div>
      <Header />
      <Row>
        <Col xl={8}></Col>
        <Col xl={8}>
          <Row justify='center'>
            <Col>
              <h2 className="titulo-h2">EMPREENDIMENTOS</h2>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={12} className="border-top border-right" align="center">
              <img id="ilhaverde" alt="" src="/images/empreendimentos/logo-ilha-verde.jpeg" height="150" />
              <div className="botaoEmp">
                <Link to="/ilha-verde">
                  <Button type="primary" shape="round" block>ENTRAR</Button>
                </Link>
              </div>
            </Col>
            <Col span={12} className="border-top border-left" align="center">
              <img id="condado" alt="" src="/images/empreendimentos/logo-garden.jpeg" height="150" />
              <div className="botaoEmp">
                <Link to="/garden">
                  <Button type="primary" shape="round" block>ENTRAR</Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="border-top border-right border-botton" align="center">
              <img id="condado" alt="" src="/images/empreendimentos/logo-jardins-condado.jpeg" height="150" />
              <div className="botaoEmp">
                <Link to="/condado">
                  <Button type="primary" shape="round" block>ENTRAR</Button>
                </Link>
              </div>
            </Col>
            <Col span={12} className="border-top border-left border-botton" align="center">
              <img id="condado" alt="" src="/images/empreendimentos/logo-lagos.jpeg" height="150" />
              <div className="botaoEmp">
                <Link to="/lagos-4">
                  <Button type="primary" shape="round" block>ENTRAR</Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="border-top border-botton" align="center">
              <h2 className="titulo-h2">AGUARDE NOVIDADES</h2>
              <img id="condado" alt="" src="/images/empreendimentos/logo-condado.jpeg" height="150" />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24} align="center">
              <div className="botaoEmp">
                <Button type="primary" shape="round" block onClick={() => props.logout()}>Sair</Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={8}></Col>
      </Row>
    </div>
  )
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Empreendimentos);
