import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Empreendimentos from '../../views/empreendimentos';
import FichaInteresse from '../../views/fichaInteresse';
import IlhaVerde from '../../views/empreendimentos/ilhaVerde';
import Condado from '../../views/empreendimentos/condado';
import Lagos4 from '../../views/empreendimentos/lagos4';
import Garden from '../../views/empreendimentos/garden';

const ProtectedRoutes = (props) => {
  return (
    <Switch>      
      <Route exact path="/ficha-interesse" component={FichaInteresse} />
      <Route exact path="/ilha-verde" component={IlhaVerde} />
      <Route exact path="/condado" component={Condado} />
      <Route exact path="/lagos-4" component={Lagos4} />
      <Route exact path="/garden" component={Garden} />
      <Route exact path="/" component={Empreendimentos} />
    </Switch>
  )
};

export default ProtectedRoutes;
