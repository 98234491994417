import axios from 'axios';

import {USER_FETCHED, USER_LOGOUT} from './types';

import {API_LOGIN} from '../consts';

const logar = async (login) => {
  const retorno = await axios.post(API_LOGIN, login)
  // const retorno = {data: {usuario: 'mota', token: 'asdasfasdasdasd'}};
  return (
    {
      type: USER_FETCHED,
      payload: retorno.data,
    }
  )
}

const logout = async () => {
  return ({
    type: USER_LOGOUT,
  })
}

export {logar, logout};